@import "~antd/dist/antd.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:normal,600,bold");

html,
body,
#root,
.ant-layout,
.ant-layout-content,
.ant-layout-content > div {
  height: 100%;
  background-color: #ffffff;
}

.anticon {
  vertical-align: baseline;
}
.ant-btn.ant-bg-primary,
.modal-lobby .ant-modal-close {
  background-color: #ed5b28 !important;
  border-color: transparent !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}
.ant-btn.ant-bg-primary:hover,
.modal-lobby .ant-modal-close:hover {
  background-color: #ffbb96;
}

.bg-login {
  object-fit: cover;
  object-position: left;
}
.layout-login {
  padding: 1rem 8rem;
  max-width: 800px;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.logo-login {
  width: 150px;
}
.form-login > input {
  line-height: 1.5;
  background-color: rgba(102, 50, 89, 0.1) !important;
  padding: 0.75rem 2rem !important;
  border: 1px solid #e4e6ef;
  border-radius: 0.675rem;
}
.form-login a {
  color: #505050;
  text-decoration: none;
}
.form-login a:hover {
  font-weight: bold;
}

.admin-sider {
  background: linear-gradient(to bottom, #d9d9d9 0%, #bdbdbd 100%);
}
.admin-sider.ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}
.admin-sider .ant-layout-sider-trigger {
  background-color: #8f8f8f;
}
.admin-menu a {
  text-decoration: none;
}
.admin-menu {
  background: none !important;
}
.admin-menu .ant-menu-item,
.admin-menu .ant-menu-submenu {
  margin: 5px 0 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-item a:hover {
  color: #000000;
  font-weight: bold;
}

.admin-menu .ant-menu-item-selected,
.admin-menu .ant-menu-item-selected a,
.admin-menu .ant-menu-item-selected a:hover,
.admin-menu .ant-menu-submenu-selected,
.admin-menu .ant-menu-submenu-selected a:hover {
  color: #000000 !important;
  font-weight: bold;
}
.admin-menu .ant-menu-item {
  font-weight: normal !important;
}
.admin-menu.ant-menu-inline .ant-menu-item::after,
.admin-menu.ant-menu-inline .ant-menu-submenu::after {
  border-right: 3px solid #ed5b28 !important;
}
.site-layout {
  background-color: #f0f2f5;
  display: block;
  overflow-y: auto;
}
.layout-admin {
  width: calc(100% - 60px);
  margin: 30px;
  padding: 20px;
  border-radius: 1rem;
  height: auto !important;
}
.site-layout-background {
  min-height: 50px;
  background-color: #ffffff;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.12);
}
.site-layout .profile {
  color: #505050;
  text-decoration: none !important;
}
.profile-content a {
  color: #505050;
  text-decoration: none !important;
}
.site-page-header .search-box {
  justify-content: right;
}
.site-page-header .search {
  width: 200px !important;
}
.form .ant-form-item-label {
  text-align: left !important;
}
.guidelines::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.editorClassName {
  height: 300px !important;
}

.ant-list-item {
  padding: 5px 0;
}
